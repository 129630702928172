import {Navbar,Nav,Dropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './sports_contact.png'
import styled from 'styled-components'
import {labels,setTenant} from '../helpers/Tenant';

export default function Template(props) {
    const Container = styled.div`
        margin-left:20%;
        margin-right:20%;
        @media only screen and (max-width: 600px) {
            margin-left:0%;
            margin-right:0%;
        }
    `
    const StyledNavLink = styled(Nav.Link)`
        color: white;
        &:link{
            color: white;
        }
        &:hover{
            color: white;
        }
    `

    const setTenantAndRedirect = (eventKey) => {
        setTenant(eventKey)
        window.location.href="/"
    }
    
    return (
        <>
            <Navbar bg="dark" expand="lg" variant="dark">
                <Navbar.Brand className="mr-auto">
                    <StyledNavLink href="/">Ligue de soccer <br/>Sports Contact JND</StyledNavLink>
                </Navbar.Brand> 
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                       <Nav.Link href="/standings">Classement/Calendrier</Nav.Link>
                    </Nav>
                    <Nav className="mr-auto">
                        <Nav.Link href="/players-stats">Stats {labels().player}</Nav.Link>
                    </Nav>
                    <Nav className="mr-auto">
                       <Nav.Link href="/keepers-stats">Stats {labels().keeper}</Nav.Link>
                    </Nav>
                    <Nav className="mr-auto">
                       <Nav.Link href="/rules">Règlements</Nav.Link>
                    </Nav>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {labels().tenant}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onSelect={setTenantAndRedirect} eventKey="m">Masculin</Dropdown.Item>
                            <Dropdown.Item onSelect={setTenantAndRedirect} eventKey="f">Féminin</Dropdown.Item>
                            <Dropdown.Item onSelect={setTenantAndRedirect} eventKey="masters">Maîtres</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
               </Navbar.Collapse>
               <Navbar.Brand>
                    <img
                        alt=""
                        src={logo}
                        width="200"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
            </Navbar>
            
            <Container>{props.children}</Container>
        </>
    );
  }