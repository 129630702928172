import React from "react";
import Standings from "./pages/standings/Standings"
import PlayersStats from "./pages/players-stats/PlayersStats"
import KeepersStats from "./pages/keepers-stats/KeepersStats"
import Rules from "./pages/rules/Rules"
import Home from "./pages/home/Home"
import Games from "./pages/games/Games"
import Template from "./components/Template"
import {isMobile} from 'react-device-detect';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function App() {
  const home = ()=> {
    if (isMobile) {
      return (<Template><Standings /></Template>)
    }
    return (<Template><Home /></Template>)
  }
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/standings">
            <Template><Standings /></Template>
          </Route>
          <Route path="/players-stats">
            <Template><PlayersStats /></Template>
          </Route>
          <Route path="/keepers-stats">
            <Template><KeepersStats /></Template>
          </Route>
          <Route path="/rules">
            <Template><Rules /></Template>
          </Route>
          <Route path="/games/:id">
            <Template><Games /></Template>
          </Route>
          <Route path="/">
            {home}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
