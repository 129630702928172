import {Table,Form} from 'react-bootstrap';
import {useEffect,useState} from 'react';
import {get} from '../../helpers/Fetch';
import {labels} from '../../helpers/Tenant';

export default function PlayersStats() {
    const [stats, setStats] = useState([])
    const [filteredStats, setFilteredStats] = useState([])
    const [teams, setTeams] = useState([])

    const filterTable = (event) => {
        const teamId = event.target.value
        setFilteredStats(stats.filter(info=>{
            return teamId == 0 || info.player.team.id==teamId
        }))
    }

    useEffect(() => {
        get('/players-stats')
        .then(response=>response.json())
        .then(json=>{
            setStats(json.stats)
            setFilteredStats(json.stats)
        })
        get('/teams')
        .then(response=>response.json())
        .then(json=>{
            setTeams(json.teams)
        })
    },[]);
    return (
        <>
            <h1>Stats {labels().player}</h1>
            <Form>
                <Form.Group controlId="select.team">
                    <Form.Control as="select" custom onChange={filterTable}>
                        <option value="0">Toutes les équipes</option>
                        {teams.map(team=><option value={team.id}>{team.name}</option>)}
                    </Form.Control>
                </Form.Group>
            </Form>
            <Table bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Pts</th>
                    <th>Buts</th>
                    <th>Passes</th>
                    <th>PJ</th>
                </tr>
                </thead>
                <tbody>
                {filteredStats.map(row=> <tr>
                    <td>{row.player.name}</td>
                    <td>{row.points}</td>
                    <td>{row.goals}</td>
                    <td>{row.assists}</td>
                    <td>{row.gamesPlayed}</td>
                </tr>)}
                </tbody>
            </Table>
        </>
    );
  }