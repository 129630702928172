import {Table} from 'react-bootstrap';
import {useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import {get} from '../../helpers/Fetch';

export default function Games() {
    const [game, setGame] = useState({homeTeam:{stats:[]},visitorTeam:{stats:[]}});
    let { id } = useParams();
    useEffect(() => {
        get('/games/'+id)
        .then(response=>response.json())
        .then(json=>setGame(json))
    },[]);
    const booleanToX = (value)=>{
        if(value){
            return 'X';
        };
        return '';
    }
    const renderStats = (team) => {
        return (
        <>
        <h1>{team.name}:{team.score}</h1>
            <Table bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Buts</th>
                    <th>Passes</th>
                    <th>Points</th>
                    <th>J</th>
                    <th>R</th>
                    <th>2J</th>
                </tr>
                </thead>
                <tbody>
                {team.stats.map(row=> renderRow(row))}
                </tbody>
            </Table>
            </>);
    }
    const renderRow = (row)=>{
        return (<tr>
                    <td>{row.player.name + (row.isKeeper?" (G) ":"")}</td>
                    <td>{row.goals}</td>
                    <td>{row.assists}</td>
                    <td>{row.points}</td>
                    <td>{booleanToX(row.yellowCard)}</td>
                    <td>{booleanToX(row.redCard)}</td>
                    <td>{booleanToX(row.twoYellowCards)}</td>
                </tr>)
    }
    return (
        <>
            {renderStats(game.homeTeam)}
            {renderStats(game.visitorTeam)}
        </>
    );
}