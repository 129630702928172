import {Carousel} from 'react-bootstrap';
import sponsors from './sponsors.jpg'
import winners from './w_m_2019_a.jpg'
import winnersAut2021 from './w_m_2021_a.jpeg'
import winnersHiv2022 from './w_m_2022_h.jpeg'
import winnersHiv2023 from './w_m_2023_h.jpeg'
import winnersAut2023 from './w_m_2023_a.jpg'
import winnersHiv2024 from './w_m_2024_h.jpg'
import winnersAut2022 from './w_m_2022_a.jpeg'
import winnersFemale from './w_f_2019_a.jpg'
import winnersFemaleAut2021 from './w_f_2021_a.jpeg'
import winnersFemaleAut2022 from './w_f_2022_a.jpeg'
import winnersFemaleHiv2022 from './w_f_2022_h.jpeg'
import winnersFemaleAut2023 from './w_f_2023_a.jpg'
import winnersFemaleHiv2023 from './w_f_2023_h.jpeg'
import winnersFemaleHiv2024 from './w_f_2024_h.jpg'
import styled from 'styled-components'
import {getTenant} from '../../helpers/Tenant'

export default function Home() {
    const Image = styled.img`
        width: 600px;
        height: 450px;
        
    `
    const StyledCarousel = styled(Carousel)`
        margin-top:50px;
        width: 600px;
        margin-left:200px;
    `
    const StyledText = styled.h3`
        background-color:#454d55;
    `
    const winnersMensCarouselItem = [<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersHiv2024}
            alt="Gagnants Hiver 2024"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Hiver 2024</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersAut2023}
            alt="Gagnants Automne 2023"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Automne 2023</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersHiv2023}
            alt="Gagnants Hiver 2023"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Hiver 2023</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersAut2022}
            alt="Gagnants Automne 2022"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Automne 2022</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersHiv2022}
            alt="Gagnants Hiver 2022"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Hiver 2022</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersAut2021}
            alt="Gagnants Automne 2021"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Automne 2021</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winners}
            alt="Gagnants Automne 2019"
        />
        <Carousel.Caption>
            <StyledText>Gagnants Automne 2019</StyledText>
        </Carousel.Caption>
    </Carousel.Item>]
    const winnersFemaleCarouselItem = [<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleHiv2024}
            alt="Gagnantes Hiver 2024"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Hiver 2024</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleAut2023}
            alt="Gagnantes Automne 2023"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Automne 2023</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleHiv2023}
            alt="Gagnantes Hiver 2023"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Hiver 2023</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleAut2022}
            alt="Gagnantes Automne 2022"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Automne 2022</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleHiv2022}
            alt="Gagnantes Hiver 2022"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Hiver 2022</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemaleAut2021}
            alt="Gagnantes Automne 2021"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Automne 2021</StyledText>
        </Carousel.Caption>
    </Carousel.Item>,<Carousel.Item interval={5000}>
        <Image
            className="d-block"
            src={winnersFemale}
            alt="Gagnantes Automne 2019"
        />
        <Carousel.Caption>
            <StyledText>Gagnantes Automne 2019</StyledText>
        </Carousel.Caption>
    </Carousel.Item>]
    const winnersCarouselItem = ()=>{
        const tenant = getTenant()
        switch(tenant){
            case "m": return winnersMensCarouselItem;
            case "f": return winnersFemaleCarouselItem;
            case "masters": return []
            default: return winnersMensCarouselItem
        }
    }
    return (
        <StyledCarousel>
            {winnersCarouselItem()}
            <Carousel.Item interval={5000}>
                <Image
                    className="d-block"
                    src={sponsors}
                    alt="Partenaires"
                />
                <Carousel.Caption>
                    <StyledText bg="dark">Merci aux partenaires!</StyledText>
                </Carousel.Caption>
            </Carousel.Item>
        </StyledCarousel>
    );
  }