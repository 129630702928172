export default function Rules() {
    return (
        <>
            <h1>Règlements</h1>
            <h2>Substitutions</h2>
            <p>Les substitutions sont illimitées et se font à la volée, par le centre, durant le jeu ou lors des arrêts de jeu. Le joueur sortant doit être à moins de un (1) mètre de la ligne de touche lors de l’entrée du joueur remplaçant.</p>
            <h2>Équipement</h2>
            <p>Le port des protège-tibias est obligatoire pour tous les joueurs. Ils doivent être portés sous les bas.</p>
            <h2>Durée des parties</h2>
            <p>Les parties auront une durée de 2 x 27 minutes avec 3 minutes d’échauffement et 2 minutes de pause à la mi-temps. En pratique, les matchs commencent à la 3e minute apres l’heure, se termine à la 30e minute. Depart de la 2e demie à la 32e minute et fin à la 59e minute. Cependant, si les joueurs ne sont pas prêts à l’heure prévue, le match devra être écourté car nous devons quitter le plateau à l’heure convenu. Si le match est retardé par une l’activité précédente, le match ne sera pas écourté et se terminera après le temps régulier.</p>
            <p>Dans les rondes éliminatoires, si les deux équipes sont à égalité après le temps réglementaire, une fusillade de trois (3) tirs sera réalisée.</p>
            <h3>Fusillade</h3>
            <p>Chaque équipe doit désigner 5 tireurs potentiels, les autres iront s’asseoir au banc des joueurs.</p>
            <p>L’équipe receveuse choisira de tirer en premier ou en deuxième.</p>
            <p>Si après les trois (3) tirs de la part de chaque équipe, l’égalité persiste, l’épreuve est poursuivie jusqu’à ce qu’une équipe ait marqué un but de plus que l’autre équipe.</p>
            <p>Si un gardien de but se blesse pendant l’épreuve, il peut être remplacé par n’importe quel joueur.</p>
            <p>Le tireur n’est pas limité dans son élan.</p>
            <h2>Classement</h2>
            <h3>Attribution des points</h3>
            <p>Les points au classement seront attribués selon le système suivant : une victoire vaut trois (3) points, une nulle vaut un (1) point, une défaite vaut zéro (0) point, un forfait vaut (-1) point.</p>
            <h3>Égalité au classement</h3>
            <p>En cas d’égalité au classement, les équipes seront départagées de la façon suivante :</p>
            <ol>
                <li>Résultats (V-D-N) des matchs entre les équipes à égalité;</li>
                <li>Différence entre les buts pour et les buts contre dans les matchs entre les équipes à égalité;</li>
                <li>Plus grand nombre de victoires;</li>
                <li>Différence entre les buts pour et les buts contre sur l’ensemble de la saison;</li>
                <li>Le plus grand nombre de buts pour;</li>
                <li>Le plus petit nombre de buts contre;</li>
                <li>Le plus grand nombre de buts marqués lors d’un match;</li>
                <li>Le plus petit nombre de but(s) accordé(s) lors d’un match;</li>
                <li>Le 2e plus grand nombre de buts marqués lors d’un match;</li>
                <li>Le 3e plus grand nombre de buts marqués lors d’un match;</li>
                <li>Le plus petit nombre de cartons (rouge=2);</li>
                <li>Tirage au sort.</li>
            </ol>
            <h2>Discipline</h2>
            <h3>Accumulation de cartons</h3>
            <p>Un joueur recevant un troisième avertissement (carton jaune) sera automatiquement suspendu pour un match. Un cinquième carton jaune et chaque carton supplémentaire méritera une suspension d’un match. Une expulsion (carton rouge) pourra entraîner une suspension s'il s'agit d'un geste violent , ou anti-sportif, selon la gravité de l’infraction. Dans le cas contraire (ex: main pour empêcher un but, main à l'extérieur de la zone pour un gardien, etc...) le joueur expulsé pourra jouer le prochain match.</p>
            <h3>Avertissement</h3>
            <p>Un joueur recevant un carton jaune devra sortir du jeu pour 10 minutes mais pourra être remplacé sur le terrain.</p>
            <h3>Expulsion</h3>
            <p>Un joueur recevant un carton rouge sera expulsé de la partie mais pourra être remplacé sur le terrain.</p>
            <h2>Règles spécifiques</h2>
            <h3>Distance de 3 mètres</h3>
            <p>Sur tous les jeux arrêtés, un adversaire ne peut s'approcher à moins de trois (3) mètres de la balle avant que celle-ci soit bottée.</p>
            <h3>Point de penalty</h3>
            <p>Le penalty est à sept (7) mètres de la ligne de but. Le joueur exécutant le penalty doit prendre son élan à l’intérieur d’une zone de dix (10) mètres.</p>
            <h3>Coup d’envoi</h3>
            <p>Un but peut être marqué directement lors du coup d’envoi.</p>
            <h3>Coup de pied de but</h3>
            <p>La balle doit être immobilisée dans la surface de réparation et est en jeu dès que l'équipe en défensive a franchi la ligne de la surface de réparation en sa possession. Aucun attaquant ne peut toucher la balle avant quelle ne sorte de la surface de réparation. Un but peut être marqué directement.</p>
            <h3>La rentrée de touche</h3>
            <p>La rentrée de touche est effectuée par un coup franc indirect. Le joueur a 4 secondes pour faire la rentrée de touche une fois qu’il a obtenu la distance à respecter par les adversaires, faute de quoi, l’arbitre peut accorder une rentrée de touche à l’autre équipe.</p>
            <h3>Jeu du gardien de but</h3>
            <p>Le gardien de but en possession du ballon ne peut pas faire de relance à la main au-delà de la ligne médiane à moins que le ballon ne touche le sol ou un joueur avant de traverser cette ligne. Toute action contrevenant à ce règlement sera sanctionnée par un coup franc indirect sur la ligne médiane à l'endroit où le ballon a franchi cette ligne. Une fois que le gardien a la balle en main, il a six (6) secondes pour la lancer ou la remettre au sol. Un joueur peut remettre (passer) délibérément le ballon avec son pied à son gardien de but mais ce dernier ne peut le prendre avec les mains.</p>
            <h3>Les tacles</h3>
            <p>Les tacles glissés sont interdits au soccer intérieur et seront sanctionnés par un coup franc direct. Par tacle glissé, on fait allusion à un geste défensif pour enlever le ballon à un adversaire. Un joueur qui glisse pour bloquer un tir ou pour jouer un ballon libre ne commet aucune faute.</p>
            <h3>Obstacle</h3>
            <p>Si la balle touche un obstacle, un coup franc indirect sera accordé à l'autre équipe à l'endroit le plus près possible du point d’impact, à l’extérieur de la zone de réparation.</p>
            <h2>Remplacement</h2>
            <h3>En saison</h3>
            <p>On peut remplacer un joueur de 1er tour ou un gardien par qui que ce soit. Pour les autres choix, il faut demander à un joueur d’une autre équipe de la même ronde ou d’une ronde inférieure. En cas de joueur non classé sur la liste de remplacant, celui si devra être approuvé par le coordonateur de la ligue ou par le capitaine adverse.</p>
            <h3>En séries</h3>
            <p>On peut remplacer un gardien par qui que ce soit (de force équivalente dans la mesure du possible et du fair-play). On peut remplacer un joueur de 1er ou 2e ronde, par n&#39;importe quel joueur qui aura été repêché après ce dernier (voir ordre de repêchage sur la page FB des capitaines). Pour les autres choix, il n’y aura aucun remplacement si l’équipe a 2 changements ou plus. Dans le cas où l’équipe a moins de 2 changements , il faut un joueur d’une autre équipe de la même ronde ou d’une ronde inférieure. En cas de joueur non classé sur la liste de remplaçant, celui si devra être approuvé par le coordonnateur de la ligue ou par le capitaine adverse.</p>
        </>
    );
  }