export const getTenant = () => {return localStorage.getItem("liguerivesud.tenant")}

export const setTenant = (key) => {localStorage.setItem('liguerivesud.tenant',key)}

export const labels = () => {
    const mens = {tenant:"Masculin", player:"Joueurs", keeper: "Gardiens"}
    switch (getTenant()){
        case 'm': return mens;
        case 'f': return {tenant:"Féminin", player:"Joueuses", keeper: "Gardiennes"};
        case 'masters': return {tenant:"Maîtres", player:"Joueurs", keeper: "Gardiens"};
        default: return mens;
}}
