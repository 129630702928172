import {Table} from 'react-bootstrap';
import {useEffect,useState} from 'react';
import {get} from '../../helpers/Fetch';
import styled from 'styled-components';

const GamesRow = styled.tr`
    &:hover {
        cursor: pointer;
    }
`

export default function Standings() {
    const [standings, setStandings] = useState([]);
    const [games, setGames] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        get('/standings')
        .then(response=>response.json())
        .then(json=>setStandings(json.standings))
        get('/events')
        .then(response=>response.json())
        .then(json=>setEvents(json.events))
        get('/games')
        .then(response=>response.json())
        .then(json=>setGames(json.games))
    },[]);
    return (
        <>
            <h1>Classement</h1>
            <Table bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Équipe</th>
                    <th>PJ</th>
                    <th>Pts</th>
                    <th>V</th>
                    <th>D</th>
                    <th>N</th>
                    <th>BP</th>
                    <th>BC</th>
                    <th>+/-</th>
                </tr>
                </thead>
                <tbody>
                {standings.map(row=> <tr>
                    <td>{row.team.name}</td>
                    <td>{row.gamesPlayed}</td>
                    <td>{row.points}</td>
                    <td>{row.wins}</td>
                    <td>{row.defeats}</td>
                    <td>{row.draws}</td>
                    <td>{row.goalsFor}</td>
                    <td>{row.goalsAgainst}</td>
                    <td>{row.plusMinus}</td>
                </tr>)}
                </tbody>
            </Table>
            <h1>Dates Importantes</h1>
            <Table bordered hover variant="dark">
                <tbody>
                {events.map(row=> <tr>
                    <td>{row.date}</td>
                    <td>{row.name}</td>
                </tr>)}
                </tbody>
            </Table>
            <h1>Calendrier</h1>
            <Table bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Type</th>
                    <th>Terrain</th>
                    <th>Date</th>
                    <th>Équipe</th>
                    <th>Résultat</th>
                    <th>Équipe</th>
                </tr>
                </thead>
                <tbody>
                {games.map(row=> {
                        const redirect = (gameId)=>{
                            window.location.href='/games/'+gameId
                        }
                        return (<GamesRow onClick={()=>redirect(row.id)}>
                            <td>{row.type.name}</td>
                            <td>{row.field.name}</td>
                            <td>{row.date}</td>
                            <td>{row.homeTeam.name}</td>
                            <td>{row.homeTeam.score} - {row.visitorTeam.score}</td>
                            <td>{row.visitorTeam.name}</td>
                        </GamesRow>)
                    }
                )}
                </tbody>
            </Table>
        </>
    );
  }