import {Table} from 'react-bootstrap';
import {useEffect,useState} from 'react';
import {get} from '../../helpers/Fetch';
import {labels} from '../../helpers/Tenant';

export default function KeepersStats() {
    const [stats, setStats] = useState([]);

    useEffect(() => {
        get('/keepers-stats')
        .then(response=>response.json())
        .then(json=>setStats(json.stats))
    },[]);
    return (
        <>
            <h1>Stats {labels().keeper}</h1>
            <Table bordered hover variant="dark">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Moyenne</th>
                    <th>Victoire</th>
                    <th>Blanchissage</th>
                    <th>PJ</th>
                </tr>
                </thead>
                <tbody>
                {stats.map(row=> <tr>
                    <td>{row.player.name}</td>
                    <td>{row.average.toFixed(2)}</td>
                    <td>{row.wins}</td>
                    <td>{row.cleanSheets}</td>
                    <td>{row.gamesPlayed}</td>
                </tr>)}
                </tbody>
            </Table>
        </>
    );
  }